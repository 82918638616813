// import { breakpoints } from '../variables.yml';

import 'focus-visible';
import './vendor/gsap-animations.js';
import { setupTweenScale } from './vendor/tween-scale';

import yall from 'yall-js';
import EmblaCarousel from 'embla-carousel';
import ClassNames from 'embla-carousel-class-names';
import { LightBox } from './vendor/LightBox.js';
import './vendor/ajax.js';
import { LoadOMatic } from './vendor/loadomatic.js';
import VideoPlayer from './vendor/videoplayer.js';
import { Search } from './vendor/search.js';
import { setupShareLinks } from './vendor/setup-share-links.js';
import { getWindowWidth } from './helpers.js';

export * from './CookieConsentManager.js';

( () => {
	// Lazy loading
	document.addEventListener( 'DOMContentLoaded', () => {
		yall( {
			observeChanges: true,
			lazyClass: 'lazy--ready',
			events: {
				load: ( e ) => {
					if ( !e.target.classList.contains( 'lazy' ) && e.target.nodeName === 'IMG' ) {
						e.target.classList.add( 'lazy--loaded' );
					}
				}
			}
		} );
	} );
} )();

( () => {
	// Instantiate search
	const general_search = new Search(); // eslint-disable-line
	if ( document.getElementById( 'js-people-search' ) ) {
		const people_search = new Search(   // eslint-disable-line
			document.getElementById( 'js-people-search' ),
			document.getElementById( 'js-people-search-input' ),
			document.getElementById( 'js-people-search-results-receptacle' ),
			['people'],
			'suggested-people-template',
			'people-search/'
		);
	}

	if ( document.getElementById( 'js-contact-search' ) ) {
		const contact_form_search = new Search(  // eslint-disable-line
			document.getElementById( 'js-contact-search' ),
			document.getElementById( 'who' ),
			document.getElementById( 'js-contact-search-receptacle' ),
			['people'],
			'suggested-contact-template',
			'people-search/'
		);
	}

} )();

( () => {
	// fill search input
	document.addEventListener( 'click', ( e ) => {
		if ( e.target.classList.contains( 'contact__suggestion' ) ) {
			e.preventDefault();
			const input = document.getElementById( 'who' );
			const template = document.getElementById( 'js-contact-search-receptacle' );
			input.value = e.target.dataset.value;
			template.innerHTML = '';
		}
	} );
} )();

( () => {
	// reveal up
	const revealables = document.querySelectorAll( '.reveal-up' );
	if ( !revealables ) {
		return;
	}

	const options = {
		rootMargin: '50px 0px -50px 0px',
	};
	const delay = 0.15;

	const io = new IntersectionObserver( ( entries, observer ) => {
		let item_load = 0;
		entries.forEach( entry => {
			if ( entry.intersectionRatio > 0 ) {
				gsap.to( entry.target, {  // eslint-disable-line
					opacity: 1,
					x: 0,
					y: 0,
					duration: 0.3,
					delay: item_load * delay,
					onComplete: animate( entry.target ),
				} );
				item_load++;

				observer.unobserve( entry.target );
			}
		} );
	}, options );

	function animate( element ) {
		element.classList.add( 'revealed' );
	}

	revealables.forEach( revealable => {
		io.observe( revealable );
	} );

} )();

( () => {
	// Search
	const search_toggles = document.querySelectorAll( '.js-search-toggle' );
	const search_box = document.querySelector( '.search' );
	const search_input = document.getElementById( 'js-search-input' );
	const search_close = document.querySelector( '.search__close' );
	const search_results_container = document.querySelector( '#js-search-results-receptacle' );

	search_toggles.forEach( toggle => {
		toggle.addEventListener( 'click', () => {
			window.setTimeout( () => {
				search_input.focus();
			}, 400 );

			search_box.scrollTo( { top: 0 } );
			search_box.classList.add( 'toggled' );
			document.body.classList.add( 'toggled' );
			toggle.classList.add( 'toggler--toggled' );
		} );
	} );

	search_close.addEventListener( 'click', closeSearch );
	search_close.addEventListener( 'keydown', ( event ) => {
		if ( event.key === 'Enter' ) {
			closeSearch();
		}
	} );

	function closeSearch() {
		search_box.classList.remove( 'toggled' );
		document.body.classList.remove( 'toggled' );
		search_toggles.forEach( toggle => {
			toggle.classList.remove( 'toggler--toggled' );
		} );
	}

	window.addEventListener( 'keydown', ( e ) => {
		if ( e.key === 'Enter' && document.body.classList.contains( 'toggled' ) ) {
			e.preventDefault();
			search_box.scrollTo( {
				top: search_results_container.getBoundingClientRect().top,
				behavior: 'smooth'
			} );
		}
	} );


	window.addEventListener( 'keyup', ( e ) => {
		if ( e.key === 'Escape' ) {
			search_box.classList.remove( 'toggled' );
			document.body.classList.remove( 'toggled' );
			search_toggles.forEach( toggle => {
				toggle.classList.remove( 'toggler--toggled' );
			} );
		}
	} );
} )();

( () => {
	// Toggle mobile subnav
	const button = document.querySelector( '.subnav__current' );
	if ( !button ) {
		return;
	}
	const nav_container = document.querySelector( '.subnav__container' );
	button.addEventListener( 'click', () => {
		if ( nav_container.classList.contains( 'nav-open' ) ) {
			nav_container.classList.remove( 'nav-open' );
			return;
		}
		nav_container.classList.add( 'nav-open' );
	} );
	window.addEventListener( 'resize', () => {
		if ( nav_container.classList.contains( 'nav-open' ) ) {
			nav_container.classList.remove( 'nav-open' );
		}
	} );
} )();

( () => {
	// Initialise slider elements
	const slider_elements = [].slice.call( document.querySelectorAll( '.content-slider' ) );
	slider_elements.map( slider_element =>
		createSlider( slider_element )
	);
} )();

function createSlider( slider_element ) {
	// Image slider
	const slider_container = slider_element.querySelector( '.content-slider__container' );
	const slider_next = slider_element.querySelector( '.content-slider__button--next' );
	const slider_prev = slider_element.querySelector( '.content-slider__button--prev' );
	const slider_header_next = document.querySelector( '.content-slider__header__button--next' );
	const extra_options = JSON.parse( slider_container.getAttribute( 'data-options' ) );
	const all_options = {};
	const autoplay = slider_container.hasAttribute( 'autoplay' ) ? slider_container.getAttribute( 'autoplay' ) : false;
	const slide_interval = slider_container.hasAttribute( 'data-slide-interval' ) ? parseInt( slider_container.getAttribute( 'data-slide-interval' ), 10 ) : 3000;
	const slide_count = slider_element.querySelector( '[data-current]' );
	let key;
	let slider;
	let timer_id = null;
	const slide_data = [];
	const default_options = {
		align: 'start',
		loop: false,
		speed: 10,
		selected: 'content-slider__item--selected',
	};



	for ( key in default_options ) {
		if ( Object.prototype.hasOwnProperty.call( default_options, key ) ) {
			all_options[key] = default_options[key];
		}
	}

	for ( key in extra_options ) {
		if ( Object.prototype.hasOwnProperty.call( extra_options, key ) ) {
			all_options[key] = extra_options[key];
		}
	}

	if ( slider_container.parentElement.classList.contains( 'content-slider--smooth' ) ) {
		slider = EmblaCarousel( slider_container, all_options, [ClassNames( { selected: 'is-selected' } )] );
		let rafId = 0;
		const animate = () => {
			const engine = slider.internalEngine();
			engine.location.add( -0.025 ); // controls the speed
			engine.target.set( engine.location );
			engine.scrollLooper.loop( -1 );
			engine.slideLooper.loop();
			engine.translate.to( engine.location );
			rafId = requestAnimationFrame( animate );
		};
		const startAutoScroll = () => {
			rafId = requestAnimationFrame( animate );
		};
		const stopAutoScroll = () => {
			rafId = cancelAnimationFrame( rafId ) || 0;
		};
		slider.on( 'settle', startAutoScroll );
		slider.on( 'pointerDown', stopAutoScroll );

		startAutoScroll();
	} else if ( autoplay ) {
		slider = EmblaCarousel( slider_container, all_options, [ClassNames( { selected: 'is-selected' } )] );
		const slides = slider.slideNodes();
		for ( let i = 0; i < slides.length; i++ ) {
			slide_data.push( createSlideObject( slides[i], i ) );
		}
		slider.on( 'init', () => {
			startSlideShow();
			handleSlideType( slider );
		} );
		slider.on( 'select', () => {
			handleSlideType( slider );
		} );

	} else {
		slider = EmblaCarousel( slider_container, all_options, [ClassNames( { selected: 'is-selected' } )] );
	}

	if ( slider_container.parentElement.classList.contains( 'content-slider--scale' ) ) {
		const { applyTweenScale, removeTweenScale } = setupTweenScale( slider );
		slider
			.on( 'init', applyTweenScale )
			.on( 'scroll', applyTweenScale )
			.on( 'reInit', applyTweenScale )
			.on( 'destroy', removeTweenScale );
	}

	if ( slider_next && slider_prev ) {
		slider_next.addEventListener( 'click', () => {
			slider.scrollNext();
		}, false );

		slider_prev.addEventListener( 'click', () => {
			slider.scrollPrev();
		}, false );
		window.addEventListener( 'load', () => {
			showOrHideArrows();
		} );
		window.addEventListener( 'resize', () => {
			showOrHideArrows();
		} );
	}

	if ( slider_header_next ) {
		slider_header_next.addEventListener( 'click', () => {
			slider.scrollNext();
		}, false );
	}

	if ( slide_count ) {
		updateSlideCount( slider, slide_count );
	}

	function showOrHideArrows() {
		const slides = slider_container.querySelectorAll( '.content-slider__item' );
		let total_width = 0;
		for ( let i = 0; i < slides.length; i++ ) {
			total_width += slides[i].getBoundingClientRect().width;
		}
		if ( total_width < getWindowWidth() ) {
			slider_next.style.display = 'none';
			slider_prev.style.display = 'none';
		} else {
			slider_next.style.display = 'flex';
			slider_prev.style.display = 'flex';
		}
	}

	function handleSlideType( slider ) {
		const current_slide = slide_data[slider.selectedScrollSnap()];
		if ( current_slide.type === 'video' ) {
			current_slide.item.play();
			clearInterval();
		} else {
			autoplayer();
		}
	}

	function createSlideObject( el, index ) {
		if ( el.getAttribute( 'data-slide-type' ) === 'video' ) {
			const video = el.querySelector( 'video' );
			const player = new VideoPlayer( { container: el, video: video, autoplay: index === 0 }, true );
			player.video.addEventListener( 'ended', () => {
				slider.scrollNext();
			}, false );
			player.addCallback( 'error', () => {
				autoplayer();
			} );
			player.addCallback( 'durationchange', () => {
				slide_data[index].timer = player.video.duration * 1000;
			} );
			return { item: player, type: 'video', timer: slide_interval };
		}
		return { item: null, type: 'image', timer: slide_interval };
	}

	function clearInterval() {
		window.clearInterval( timer_id );
	}

	function resetInterval() {
		clearInterval();
		timer_id = window.setInterval( () => {
			slider.scrollNext();
		}, slide_interval );
	}

	function autoplayer() {
		resetInterval();
	}

	function startSlideShow() {
		if ( slide_data[0].type !== 'video' ) {
			autoplayer();
		}
	}

	function updateSlideCount( embla, el ) {
		slider.on( 'select', () => {
			el.innerHTML = embla.selectedScrollSnap() + 1;
		} );
	}

}

( () => {
	// Video embeds
	function buildVideoPlayer( container ) {
		const embed_url = container.getAttribute( 'data-embed-url' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', 1640 );
		iframe.setAttribute( 'height', 923 );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'title', 'Embedded video content' );
		iframe.setAttribute( 'frameborder', 0 );
		iframe.setAttribute( 'allowfullscreen', true );
		iframe.setAttribute( 'allow', 'autoplay' );
		container.classList.add( 'flex-container' );
		container.classList.add( 'video-embed--playing' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	function handleVideoClick( container ) {
		return ( event ) => {
			event.preventDefault();
			buildVideoPlayer( container );
		};
	}

	const videos = document.querySelectorAll( '.video-embed' );
	for ( let j = 0; j < videos.length; j += 1 ) {
		videos[j].addEventListener( 'click', handleVideoClick( videos[j] ) );
	}

} )();

( () => {
	// Podcast embeds
	function buildPodcastPlayer( button ) {
		const embed_url = button.getAttribute( 'data-embed-url' );
		const container = button.closest( '.basic-listing__image' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', 810 );
		iframe.setAttribute( 'height', 460 );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'frameborder', 0 );
		iframe.setAttribute( 'allow', 'autoplay' );
		container.classList.add( 'flex-container' );
		container.classList.add( 'podcast-embed--playing' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	function handlePodcastClick( button ) {
		return ( event ) => {
			event.preventDefault();
			buildPodcastPlayer( button );
		};
	}

	const podcast_embeds = document.querySelectorAll( '.js-play-podcast' );
	for ( let i = 0; i < podcast_embeds.length; i++ ) {
		podcast_embeds[i].addEventListener( 'click', handlePodcastClick( podcast_embeds[i] ) );
	}
} )();

( () => {
	// Lightbox
	new LightBox();
} )();

( () => {
	// Toggle filter menu
	const filter_toggles = document.querySelectorAll( '.filter__toggle' );
	if ( !filter_toggles ) {
		return;
	}
	const menus = document.querySelectorAll( '.filter__menu' );

	filter_toggles.forEach( toggle => {
		toggle.addEventListener( 'click', ( e ) => {
			const menu = e.currentTarget.nextElementSibling;
			if ( menu.classList.contains( 'open' ) ) {
				menu.classList.remove( 'open' );
				e.currentTarget.classList.remove( 'open' );
				return;
			}
			for ( let i = 0; i < menus.length; i++ ) {
				menus[i].classList.remove( 'open' );
				filter_toggles[i].classList.remove( 'open' );
			}
			menu.classList.add( 'open' );
			toggle.classList.add( 'open' );
		}, false );
	} );
} )();

( () => {
	// Draw svgs
	const svgs = document.querySelectorAll( '.svg' );
	if ( !svgs ) {
		return;
	}

	window.addEventListener( 'load', () => {
		svgs.forEach( ( svg, index ) => {
			setTimeout( () => {
				drawSvg( svg, 0.5 );
			}, index * 500 );
		} );
	} );
} )();

( () => {
	// People overview animate images in
	const container = document.querySelector( '.people-intro' );
	if ( !container ) {
		return;
	}
	const images = document.querySelectorAll( '.people-intro__image' );
	const new_images = document.querySelectorAll( '.people-intro__remaining-image' );
	const new_image_sources = getImageSourceArray( new_images );
	const shown_image_indices = [];
	const animation_timings = [1, 1, 1, 3, 3, 7, 9, 15, 21];
	const leftover_images = [];
	const animation_done_event = new Event( 'animation_done', {
		bubbles: true,
		cancelable: true,
		composed: false,
	} );

	function getImageSourceArray( elements ) {
		const data = [];
		for ( let i = 0; i < elements.length; i++ ) {
			const el = elements[i];
			data.push( el.dataset.imageUrl );
		}
		return data;
	}

	const randNumExclude = ( min, max, exclude = [] ) => {
		let num = Math.floor( Math.random() * ( max - min + 1 - exclude.length ) + min );
		exclude
			.slice()
			.sort( ( a, b ) => a - b )
			.every( ( exeption ) => exeption <= num && ( num++, true ) );
		return num;
	};

	const getSeveralNums = ( num ) => {
		const nums = [];
		const max = images.length - 1;
		for ( let i = 0; i < num; i++ ) {
			const num = randNumExclude( 0, max, shown_image_indices );
			shown_image_indices.push( num );
			nums.push( num );
		}
		return nums;
	};

	const getNonExclusiveNums = ( min, max, num ) => {
		const arr = [];
		while ( arr.length < num ) {
			const r = Math.floor( Math.random() * ( max - min + 1 ) + min );
			if ( arr.indexOf( r ) === -1 ) {
				arr.push( r );
			}
		}
		return arr;
	};

	const showimage = ( images_to_show, els ) => {
		for ( let j = 0; j < images_to_show.length; j++ ) {
			els[images_to_show[j]].classList.add( 'visible' );
		}
	};

	const getLeftoverIndexes = () => {
		for ( let l = 0; l < images.length; l++ ) {
			if ( !shown_image_indices.includes( l ) ) {
				leftover_images.push( l );
			}
		}
		return leftover_images;
	};

	const playAnimationWithDelay = ( animations, interval, callback ) => {
		let iterations = animations.length;
		let leftovers;
		for ( let x = 0; x < animations.length; x++ ) {
			setTimeout( () => {  // eslint-disable-line
				showimage( getSeveralNums( animations[x] ), images );
				if ( !--iterations ) {
					leftovers = callback();
					showimage( leftovers, images );
					container.dispatchEvent( animation_done_event );

				}
			}, x * ( interval * 1000 ) );
		}
	};

	const replaceImageAnimation = () => {
		setInterval( () => {
			// get random numbers between 0 and 83
			const replace_indexes = getNonExclusiveNums( 0, 83, 3 );

			replace_indexes.forEach( index => {
				// get image from DOM
				const image_container = images[index];
				const image = image_container.getElementsByTagName( 'img' )[0];
				// hide image
				image_container.classList.remove( 'visible' );

				const handleSourceChange = new Promise( ( resolve ) => {
					setTimeout( () => {
						new_image_sources.push( image.src );
						image.src = new_image_sources[0];
						resolve( new_image_sources );
					}, 1000 );
				} );

				handleSourceChange
					.then( () => {
						image_container.classList.add( 'visible' );
						new_image_sources.shift();
					} );
			} );
		}, 2000 );
	};

	playAnimationWithDelay( animation_timings, 0.5, getLeftoverIndexes );
	container.addEventListener( 'animation_done', () => {
		replaceImageAnimation();
	} );


} )();

( () => {
	// Toggle contact form
	const contact_toggles = document.querySelectorAll( '.toggle-contact' );
	if ( !contact_toggles ) {
		return;
	}

	contact_toggles.forEach( toggle => {
		const container = document.getElementById( toggle.getAttribute( 'data-contact-type' ) );
		const contact_close = container.querySelector( '.contact__close' );

		toggle.addEventListener( 'click', () => {
			container.classList.add( 'open' );
			document.body.classList.add( 'contact-open' );
		} );

		contact_close.addEventListener( 'click', () => {
			container.classList.remove( 'open' );
			document.body.classList.remove( 'contact-open' );
		} );
	} );
} )();

( () => {
	// Play/pause autoplaying videos
	const containers = document.querySelectorAll( '.video-autoplay__container' );
	if ( !containers ) {
		return;
	}

	for ( let i = 0; i < containers.length; i++ ) {
		const container = containers[i];
		const video = container.querySelector( 'video' );
		const button = container.querySelector( '.js-autoplay-button' );
		const lb_button = container.querySelector( '.js-open-lightbox' );

		if ( button )  {
			button.addEventListener( 'click', ( e ) => {
				e.preventDefault();
				if ( video.paused ) {
					video.play();
					button.classList.toggle( 'playing', false );
					if ( button.classList.contains( 'video-autoplay__button--text' ) ) {
						button.innerText = 'Pause';
					}
				} else {
					video.pause();
					button.classList.toggle( 'playing', true );
					if ( button.classList.contains( 'video-autoplay__button--text' ) ) {
						button.innerText = 'Resume';
					}
				}
			} );
		}

		if ( lb_button ) {
			const lb = document.getElementById( lb_button.dataset.lightboxId );
			const close = lb.querySelector( '.content-slider__lightbox-close' );
			const container = lb.querySelector( '.flex-container' );
			lb_button.addEventListener( 'click', () => {
				const iframe = document.createElement( 'iframe' );
				iframe.setAttribute( 'src', 'https://player.vimeo.com/video/' + lb_button.dataset.videoId + '?autoplay=true&muted=false&dnt=true&controls=true' );
				iframe.setAttribute( 'title', 'Embedded video content' );
				iframe.setAttribute( 'frameborder', 0 );
				iframe.setAttribute( 'allowfullscreen', true );
				iframe.setAttribute( 'allow', 'autoplay' );
				container.appendChild( iframe );
				lb.classList.add( 'open' );

			} );

			close.addEventListener( 'click', () => {
				lb.querySelector( 'iframe' ).remove();
				lb.classList.remove( 'open' );
			} );
		}
	}
} )();

( () => {
	//
} )();

setupShareLinks();

( () => {
	//Keyboard accessibility - revise tabindex order, ignore certain elements when they don't appear on page
	function removeTabIndexes( class_name ) {

		const actionable_elements = document.querySelectorAll( '.' + class_name + ' a, .' + class_name + ' button, .' + class_name + ' input, .' + class_name + ' select, .' + class_name + ' textarea, .' + class_name + ' [tabindex]' );

		actionable_elements.forEach( element => {
			element.setAttribute( 'tabindex', '-1' );
		} );
	}

	function resetTabIndexes( class_name ) {
		const elements_with_tab_index = document.querySelectorAll( '.' + class_name + ' [tabindex]' );
		elements_with_tab_index.forEach( element => {
			element.removeAttribute( 'tabindex' );
		} );
	}

	let nav_opened_count = 0;

	function searchForVisibleElements() {
		const search_overlay = document.querySelector( '.search' );

		if ( search_overlay && search_overlay.classList.contains( 'toggled' ) ) {
			resetTabIndexes( 'search' );
		} else {
			removeTabIndexes( 'search' );
		}

		if ( document.body.classList.contains( 'cookie-banner-open' ) ) {
			resetTabIndexes( 'cookie-banner' );
		} else {
			removeTabIndexes( 'cookie-banner' );
		}

		if ( document.body.classList.contains( 'navigation-open' ) ) {
			nav_opened_count++;
			resetTabIndexes( 'overlay-navigation' );
			const first_nav_link = document.getElementsByClassName( 'overlay-navigation__link' )[0];
			if ( nav_opened_count <= 1 ) {
				first_nav_link.focus();
			}

		} else {
			removeTabIndexes( 'overlay-navigation' );
			nav_opened_count = 0;
		}

	}

	searchForVisibleElements();

	window.addEventListener( 'keydown', () => {
		searchForVisibleElements();
	} );
	window.addEventListener( 'click', () => {
		searchForVisibleElements();
	} );
} )();

function drawSvg( svg, transition_in_s = 5 ) {
	const svg_paths = svg.querySelectorAll( 'path' );
	for ( let i = 0; i < svg_paths.length; i++ ) {
		const path = svg_paths[i];
		const path_length = path.getTotalLength();
		path.style.transition = path.style.WebkitTransition = 'none';
		path.style.strokeDasharray = path_length + ' ' + path_length;
		path.style.strokeDashoffset = path_length;
		path.getBoundingClientRect();
		path.style.transition = path.style.WebkitTransition = `stroke-dashoffset ${transition_in_s}s ease-in-out`;
		path.style.strokeDashoffset = '0';
		svg.style.opacity = 1;
	}
}

function buildLoadOMatic( options ) {
	// Load more
	const container = document.querySelector( '.loadomatic' );
	if ( !container ) {
		return;
	}

	const loadomatic = new LoadOMatic( options );
	loadomatic.trigger.addEventListener( 'click', () => {
		loadomatic.loadMore();
	} );
}

window.runPageScript = () => {
	if ( typeof window.pickeverard === 'function' ) {
		window.pickeverard( {
			buildLoadOMatic: buildLoadOMatic
		} );
	}
};
window.runPageScript();

function doToggle( toggler ) {
	const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );

	for ( let i = 0; i < togglees.length; i += 1 ) {
		togglees[i].classList.toggle( 'toggled' );
	}
	toggler.classList.toggle( 'toggler--toggled' );
}

( () => {
	window.addEventListener( 'click', ( event ) => {
		if ( !event.target.classList.contains( 'toggler' ) ) {
			return;
		}
		doToggle( event.target );
	} );
} )();

( () => {
	// Newsletter sign-up email auto-fill
	const email_form = document.querySelector( '#email-form' );

	if ( !email_form ) {
		return;
	}

	email_form.addEventListener( 'submit', ( e ) => {
		e.preventDefault();
		const form_data = new FormData( email_form );
		const email = form_data.get( 'email' );

		window.location.href = window.location.origin + `/newsletter?email=${email}`;
	} );
} )();